import React, { useState, useEffect, useRef } from "react";
import { Link as ScrollLink, Events } from "react-scroll";
import logo from "../assets/scarlet-logo.jpg";
const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const navbarRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // Intersection Observer callback for scrollspy
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };
    const options = {
      rootMargin: "-50% 0px -50% 0px", // Adjust as needed
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    // Add observer to all sections you want to track
    const sectionElements = document.querySelectorAll("section.scrollspy");
    sectionElements.forEach((section) => {
      observer.observe(section);
    });
    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);
  // Add an event listener to detect when scrolling has stopped
  useEffect(() => {
    Events.scrollEvent.register("end", () => {
      // Do something when scrolling ends (if needed)
    });

    return () => {
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <nav ref={navbarRef} className="bg-slate-900 p-6 fixed top-0 z-50 w-full">
      <div className="container mx-auto flex justify-between items-center">
        <a href="#" className="text-white text-xl font-semibold">
          <img
            src={logo}
            alt="Scarlet Logo"
            className="w-[70px] h-[50px] lg:w-[80px] lg:h-[60px] "
          />
        </a>

        <div className="hidden md:flex space-x-4">
          <a
            href="#"
            className={`text-white nav-link ${
              activeSection === "home" ? "font-bold" : ""
            }`}
            onClick={closeMobileMenu}
          >
            Home
          </a>
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
            className={`text-white nav-link ${
              activeSection === "about" ? "font-bold" : ""
            }`}
            onClick={closeMobileMenu}
          >
            About
          </ScrollLink>
          <ScrollLink
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
            className={`text-white nav-link ${
              activeSection === "services" ? "font-bold" : ""
            }`}
            onClick={closeMobileMenu}
          >
            Services
          </ScrollLink>
          <ScrollLink
            to="footer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={300}
            className={`text-white nav-link ${
              activeSection === "footer" ? "font-bold" : ""
            }`}
            onClick={closeMobileMenu}
          >
            Contact
          </ScrollLink>
        </div>

        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="md:hidden bg-slate-900 text-white">
          <a
            href="#"
            className="block py-4 px-[50%] cursor-pointer"
            onClick={closeMobileMenu}
            spy="true"
            smooth="true"
            offset={-70}
            duration={500}
          >
            Home
          </a>
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="block py-4 mb-2 px-[50%] cursor-pointer"
            onClick={closeMobileMenu}
          >
            About
          </ScrollLink>
          <ScrollLink
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="block py-4 px-[50%] cursor-pointer"
            onClick={closeMobileMenu}
          >
            Services
          </ScrollLink>
          <ScrollLink
            to="footer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="block py-4 px-[50%] cursor-pointer"
            onClick={closeMobileMenu}
          >
            Contact
          </ScrollLink>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
