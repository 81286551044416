import { lazy } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Root from "./components/Root";
import Error from "./components/Error";
import Nav from "./components/Nav";

const Home = lazy(() => wait(timer).then(() => import("./components/Home")));

const About = lazy(() =>
  wait(timer).then(() =>
    import("./components/About").then((module) => {
      return {
        default: module.About,
      };
    })
  )
);

const Services = lazy(() =>
  wait(timer).then(() =>
    import("./components/Services").then((module) => {
      return {
        default: module.Services,
      };
    })
  )
);
const Contact = lazy(() =>
  wait(timer).then(() =>
    import("./components/Contact").then((module) => {
      return {
        default: module.Contact,
      };
    })
  )
);

const App = () => {
  // initialize aos
  Aos.init({
    duration: 1800,
    offset: 100,
  });
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Root />} errorElement={<Error />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="contact" element={<Contact />} />
          <Route errorElement={<Error />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
const timer = 500;

function wait(timer) {
  return new Promise((resolve) => setTimeout(resolve, timer));
}
