import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Circles } from "react-loader-spinner";
// import Nav from "./Nav";

const Root = () => {
  return (
    <>
      <div>
        <Suspense
          fallback={
            <Circles
              height="80"
              width="40"
              color="red"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </>
  );
};
export default Root;
